<template lang="">
    <div class="course-detail-page">
        <div class="box-1" v-if="!isLoading">
            <div class="course-info">
                <a-row :gutter="[16]">
                    <a-col :xs="24" :sm="12" :lg="6">
                        <img
                            :src="courseDetail.thumbnail"
                            :alt="courseDetail.title"
                            v-if="linkImgRule(courseDetail.thumbnail)"
                        />
                        <img src="../../assets/images/no_image.jpg" :alt="courseDetail.title" v-else />
                    </a-col>
                    <a-col :xs="24" :sm="12" :lg="18">
                        <div>
                            <h2 class="title">{{ courseDetail.title }}</h2>
                            <Truncate
                                class="description"
                                :truncated="true"
                                :clamp="$t('course_detail.show_more')"
                                :less="$t('course_detail.show_less')"
                                type="html"
                                :length="500"
                                :text="`<span>${courseDetail.description || ''}</span>`"
                            />
                            <div class="hashtag">
                                <p v-for="hashtag in courseDetail.array_hashtag">#{{ hashtag }}</p>
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </div>

            <a-collapse class="my-collapse" v-model="activeKey" v-if="courseDetail.course_chapters.length > 0">
                <a-collapse-panel
                    v-for="(item, index) in courseDetail.course_chapters"
                    :key="`${index}`"
                    :header="item.chapter ? item.chapter.title : `Chapter ${index + 1}`"
                >
                    <div class="chapter-list">
                        <div v-for="sectionObject in item.course_sections">
                            <div v-if="sectionObject.section">
                                <div class="chapter-item" v-if="sectionObject.section.lesson">
                                    <router-link
                                        :to="{
                                            path: `/lesson-page/${sectionObject.section.lesson.id}`,
                                            query: { course_id: courseDetail.id },
                                        }"
                                    >
                                        <div class="name">
                                            <img src="../../assets/images/icon-lesson.png" alt="icon-lesson" />
                                            <p>{{ sectionObject.section.lesson.title }}</p>
                                        </div>
                                        <div class="box-progress lesson">
                                            <span>{{ sectionObject.section.lesson.progress }}%</span>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="chapter-item" v-if="sectionObject.section.test">
                                    <router-link
                                        :to="{
                                            path: `/test-page/${sectionObject.section.test.id}`,
                                            query: { course_id: courseDetail.id },
                                        }"
                                        v-if="sectionObject.section.test"
                                    >
                                        <div class="name">
                                            <img src="../../assets/images/icon-test.png" alt="icon-test" />
                                            <p class="test-name">
                                                <span>{{ sectionObject.section.test.title }}</span>
                                                <span class="ml-2" :style="{ fontSize: '14px', color: '#696969' }">
                                                    (合格ライン：{{ sectionObject.section.test.pass_score }}点)
                                                </span>
                                            </p>
                                        </div>
                                        <div class="status">
                                            <router-link
                                                :to="{
                                                    path: '/result-page',
                                                    query: {
                                                        course_id: courseDetail.id,
                                                        test_id: sectionObject.section.test.id,
                                                        test_attend_id: sectionObject.section.test.last_test_attend_id,
                                                    },
                                                }"
                                                class="history-link"
                                                v-if="sectionObject.section.test.amount_join > 0"
                                            >
                                                {{ $t('course_detail.view_history') }}
                                            </router-link>
                                            <div class="box-progress">
                                                <span v-if="sectionObject.section.test.amount_join === 0">
                                                    {{ $t('course_detail.no_join') }}
                                                </span>
                                                <span v-else>
                                                    {{ sectionObject.section.test.user_score }}点 /
                                                    {{
                                                        sectionObject.section.test.is_pass
                                                            ? $t('course_detail.pass')
                                                            : $t('course_detail.fail')
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="chapter-item" v-if="sectionObject.section.form">
                                    <div
                                        @click="handleClickForm(sectionObject.section.form)"
                                        v-if="sectionObject.section.form"
                                    >
                                        <div class="name">
                                            <img src="../../assets/images/contact-form.png" alt="icon-form" />
                                            <p>{{ sectionObject.section.form.title }}</p>
                                        </div>
                                        <div class="box-progress form">
                                            <span>{{ sectionObject.section.form.progress }}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a-collapse-panel>
            </a-collapse>

            <div class="box-noData" v-else>
                <a-empty :style="{ marginTop: '50px' }" :description="$t('no_data')" />
            </div>
        </div>

        <div class="box-2" v-if="!isLoading && nextCourse.length">
            <h2 class="title">{{ $t('course_detail.next_course') }}</h2>
            <CourseList :list="nextCourse" v-if="nextCourse.length" />
            <a-empty :description="$t('no_data')" v-else-if="!nextCourse.length" />
        </div>

        <Loading v-if="isLoading" />
    </div>
</template>

<script>
import * as _ from 'lodash';
import truncate from 'vue-truncate-collapsed';
import Loading from '../../components/Web/Loading/Loading.vue';
import CourseList from '../../components/Web/Courses/CourseList.vue';
import * as CourseAPI from '../../api/courseAPI';
import { FormQuestion } from '../../api';
import { mixinRules } from '../../mixins';

export default {
    mixins: [mixinRules],

    components: {
        Loading,
        CourseList,
        Truncate: truncate,
    },

    data() {
        return {
            activeKey: [],
            isLoading: false,
            courseDetail: null,
            nextCourse: [],
        };
    },

    created() {
        this.getDetailCourse();
        this.getNextCourse();
    },

    watch: {
        '$route.params': function () {
            this.getDetailCourse();
            this.getNextCourse();
        },
    },

    methods: {
        async getDetailCourse() {
            try {
                this.isLoading = true;
                const res = await CourseAPI.findOne(this.$route.params.id, {
                    fields: ['$all'],
                });
                this.courseDetail = res?.results?.object;
                this.activeKey = [...Array(this.courseDetail?.course_chapters?.length).keys()];
                setTimeout(() => {
                    this.isLoading = false;
                }, 500);
            } catch (error) {
                console.log('🚀 ~ file: CourseDetailPage.vue:136 ~ getDetailCourse ~ error', error);
                this.isLoading = false;
            }
        },

        async getNextCourse(page) {
            try {
                const res = await CourseAPI.getList({
                    fields: ['$all'],
                    where: { id: { $ne: this.$route.params.id } },
                    order: [['created_at_unix_timestamp', 'desc']],
                    limit: 4,
                    page,
                });
                this.nextCourse = res?.results?.objects?.rows;
            } catch (error) {
                console.log('🚀 ~ file: MyPage.vue:37 ~ getCourses ~ error', error);
            }
        },

        async handleClickForm(form) {
            if (form.retake) {
                this.$router.push({
                    path: `/form-list-page/${form.id}`,
                    query: { course_id: this.courseDetail.id },
                });
            } else {
                if (form.amount_join > 0) {
                    let record_id = '';

                    try {
                        const res = await FormQuestion.getFormAttend({
                            fields: ['id'],
                            where: { form_id: form.id },
                            order: [['created_at_unix_timestamp', 'desc']],
                            course_id: this.courseDetail.id,
                            page: 1,
                            limit: 1,
                        });
                        record_id = res?.results?.objects?.rows?.[0]?.id;
                    } catch (error) {
                        console.log('🚀 ~ file: CourseDetailPage.vue:201 ~ handleRouteForm ~ error:', error);
                    }

                    this.$router.push({
                        path: `/form-view-page/${form.id}`,
                        query: { record_id, course_id: this.courseDetail.id },
                    });
                } else {
                    this.$router.push({
                        path: `/form-write-page/${form.id}`,
                        query: { course_id: this.courseDetail.id },
                    });
                }
            }
        },
    },
};
</script>

<style lang="scss">
.course-detail-page {
    width: 100%;
    max-width: 1170px;
    padding: 0 15px;
    margin: 80px auto;
    & .box-noData {
        padding: 30px;
        background-color: #fff;
        border-radius: 16px;
    }
    & .box-2 {
        margin-top: 80px;

        & .title {
            margin-bottom: 30px;
            font-size: 24px !important;
            text-align: center;
        }
    }
}

.course-info {
    margin-bottom: 30px !important;
    padding: 30px;
    background-color: #fff;
    border-radius: 16px;

    & img {
        width: 100%;
        height: 250px;
        object-fit: cover;
    }

    & .title {
        margin-bottom: 8px;
        font-size: 30px;
    }

    & .description {
        margin-bottom: 16px;
        font-size: 16px;

        & button {
            color: #868686;
        }
    }
    & .hashtag {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        & p {
            margin-bottom: 0;
            padding: 4.5px 16px;
            font-size: 14px;
            background-color: #f2f2f2;
            color: #525252;
            border-radius: 30px;
        }
    }
}

.my-collapse {
    font-size: 16px;
    border: none;

    &.ant-collapse {
        border-radius: 16px;
        overflow: hidden;
    }

    & .ant-collapse-item {
        border: none;
    }

    & .ant-collapse-header {
        min-height: 65px;
        display: flex;
        align-items: center;
    }

    & .ant-collapse-content {
        overflow: unset;
    }

    & .ant-collapse-content-box {
        padding: 0;
    }
}

.chapter-item {
    display: flex;
    align-items: center;
    height: 65px;
    padding: 0 16px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        transform: translateZ(1px) !important;
        box-shadow: 0 4px 6px #e8eaf1 !important;
    }

    & + & {
        border-top: 1px solid #f2f3f7;
    }

    & a,
    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    & .name {
        max-width: calc(90% - 240px);
        display: flex;
        align-items: center;

        & img {
            width: 30px;
            margin-right: 10px;
        }

        & p {
            margin: 0;
            font-size: 16px;
            color: #000;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
            transition: all 0.3s ease;
        }
    }

    &:hover .name p {
        color: #636cf5;
    }

    & .status {
        display: flex;
        align-items: center;
    }

    & .history-link {
        position: relative;
        width: fit-content;
        margin-right: 15px;
        color: #007fff;
        z-index: 1;
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 0;
            background-color: transparent;
            transition: all 0.3s ease;
        }
        &:hover::after {
            background-color: #007fff;
        }
    }

    & .box-progress {
        width: 120px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 700;
        border: 1.5px solid rgb(204, 40, 29, 0.5);
        background-color: rgb(204, 40, 29, 0.2);
        color: #cc281d;
        &.lesson {
            background-color: rgba(46, 204, 64, 0.2);
            color: #2ecc40;
            border-color: rgba(46, 204, 64, 0.5);
        }
        &.form {
            background-color: rgba(99, 108, 245, 0.2);
            color: #636cf5;
            border-color: rgba(99, 108, 245, 0.5);
        }
    }
}

@media screen and (max-width: 767px) {
    .course-detail-page {
        margin: 50px auto;
    }

    .course-info {
        & .title {
            margin-top: 8px;
            font-size: 24px;
        }

        & .description {
            font-size: 14px;
        }
    }

    .my-collapse {
        font-size: 14px;
    }

    .chapter-item {
        height: 70px;
        & .name {
            max-width: calc(90% - 80px);
        }
        & .status {
            flex-direction: column-reverse;
            text-align: center;
            & a {
                margin-top: 5px;
                margin-right: 0;
            }
        }
        & p.test-name {
            display: flex;
            flex-direction: column;
            font-size: 14px;
        }
        & .test-name span {
            margin-left: 0 !important;
        }
    }

    .chapter-item {
        & .status,
        & .box-progress {
            width: 80px;
            font-size: 12px;
        }
    }
}
</style>
