import { HTTP } from '../config/http-common';
import { ROUTE_API } from '../constants/routes';
import { exportResults, convertParams } from '../utils/index';

export const getList = async (params) =>
    exportResults(
        await HTTP.get(`${ROUTE_API.COURSE}/user`, {
            params: convertParams(params),
        }),
    );

export const search = async (params) =>
    exportResults(
        await HTTP.get(`${ROUTE_API.COURSE}/search`, {
            params: convertParams(params),
        }),
    );

export const getOne = async (id) => exportResults(await HTTP.get(`${ROUTE_API.COURSE}/${id}`));

export const findOne = async (id, params) =>
    exportResults(
        await HTTP.get(`${ROUTE_API.COURSE}/${id}`, {
            params: convertParams(params),
        }),
    );

export const deleteOne = async (id) => exportResults(await HTTP.delete(`${ROUTE_API.COURSE}/${id}`));

export const updateOne = async (id, body) => exportResults(await HTTP.put(`${ROUTE_API.COURSE}/${id}`, body));

export const createOne = async (body) => exportResults(await HTTP.post(`${ROUTE_API.COURSE}`, body));
