var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"course-detail-page"},[(!_vm.isLoading)?_c('div',{staticClass:"box-1"},[_c('div',{staticClass:"course-info"},[_c('a-row',{attrs:{"gutter":[16]}},[_c('a-col',{attrs:{"xs":24,"sm":12,"lg":6}},[(_vm.linkImgRule(_vm.courseDetail.thumbnail))?_c('img',{attrs:{"src":_vm.courseDetail.thumbnail,"alt":_vm.courseDetail.title}}):_c('img',{attrs:{"src":require("../../assets/images/no_image.jpg"),"alt":_vm.courseDetail.title}})]),_c('a-col',{attrs:{"xs":24,"sm":12,"lg":18}},[_c('div',[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.courseDetail.title))]),_c('Truncate',{staticClass:"description",attrs:{"truncated":true,"clamp":_vm.$t('course_detail.show_more'),"less":_vm.$t('course_detail.show_less'),"type":"html","length":500,"text":("<span>" + (_vm.courseDetail.description || '') + "</span>")}}),_c('div',{staticClass:"hashtag"},_vm._l((_vm.courseDetail.array_hashtag),function(hashtag){return _c('p',[_vm._v("#"+_vm._s(hashtag))])}),0)],1)])],1)],1),(_vm.courseDetail.course_chapters.length > 0)?_c('a-collapse',{staticClass:"my-collapse",model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},_vm._l((_vm.courseDetail.course_chapters),function(item,index){return _c('a-collapse-panel',{key:("" + index),attrs:{"header":item.chapter ? item.chapter.title : ("Chapter " + (index + 1))}},[_c('div',{staticClass:"chapter-list"},_vm._l((item.course_sections),function(sectionObject){return _c('div',[(sectionObject.section)?_c('div',[(sectionObject.section.lesson)?_c('div',{staticClass:"chapter-item"},[_c('router-link',{attrs:{"to":{
                                        path: ("/lesson-page/" + (sectionObject.section.lesson.id)),
                                        query: { course_id: _vm.courseDetail.id },
                                    }}},[_c('div',{staticClass:"name"},[_c('img',{attrs:{"src":require("../../assets/images/icon-lesson.png"),"alt":"icon-lesson"}}),_c('p',[_vm._v(_vm._s(sectionObject.section.lesson.title))])]),_c('div',{staticClass:"box-progress lesson"},[_c('span',[_vm._v(_vm._s(sectionObject.section.lesson.progress)+"%")])])])],1):_vm._e(),(sectionObject.section.test)?_c('div',{staticClass:"chapter-item"},[(sectionObject.section.test)?_c('router-link',{attrs:{"to":{
                                        path: ("/test-page/" + (sectionObject.section.test.id)),
                                        query: { course_id: _vm.courseDetail.id },
                                    }}},[_c('div',{staticClass:"name"},[_c('img',{attrs:{"src":require("../../assets/images/icon-test.png"),"alt":"icon-test"}}),_c('p',{staticClass:"test-name"},[_c('span',[_vm._v(_vm._s(sectionObject.section.test.title))]),_c('span',{staticClass:"ml-2",style:({ fontSize: '14px', color: '#696969' })},[_vm._v(" (合格ライン："+_vm._s(sectionObject.section.test.pass_score)+"点) ")])])]),_c('div',{staticClass:"status"},[(sectionObject.section.test.amount_join > 0)?_c('router-link',{staticClass:"history-link",attrs:{"to":{
                                                path: '/result-page',
                                                query: {
                                                    course_id: _vm.courseDetail.id,
                                                    test_id: sectionObject.section.test.id,
                                                    test_attend_id: sectionObject.section.test.last_test_attend_id,
                                                },
                                            }}},[_vm._v(" "+_vm._s(_vm.$t('course_detail.view_history'))+" ")]):_vm._e(),_c('div',{staticClass:"box-progress"},[(sectionObject.section.test.amount_join === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('course_detail.no_join'))+" ")]):_c('span',[_vm._v(" "+_vm._s(sectionObject.section.test.user_score)+"点 / "+_vm._s(sectionObject.section.test.is_pass ? _vm.$t('course_detail.pass') : _vm.$t('course_detail.fail'))+" ")])])],1)]):_vm._e()],1):_vm._e(),(sectionObject.section.form)?_c('div',{staticClass:"chapter-item"},[(sectionObject.section.form)?_c('div',{on:{"click":function($event){return _vm.handleClickForm(sectionObject.section.form)}}},[_c('div',{staticClass:"name"},[_c('img',{attrs:{"src":require("../../assets/images/contact-form.png"),"alt":"icon-form"}}),_c('p',[_vm._v(_vm._s(sectionObject.section.form.title))])]),_c('div',{staticClass:"box-progress form"},[_c('span',[_vm._v(_vm._s(sectionObject.section.form.progress)+"%")])])]):_vm._e()]):_vm._e()]):_vm._e()])}),0)])}),1):_c('div',{staticClass:"box-noData"},[_c('a-empty',{style:({ marginTop: '50px' }),attrs:{"description":_vm.$t('no_data')}})],1)],1):_vm._e(),(!_vm.isLoading && _vm.nextCourse.length)?_c('div',{staticClass:"box-2"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('course_detail.next_course')))]),(_vm.nextCourse.length)?_c('CourseList',{attrs:{"list":_vm.nextCourse}}):(!_vm.nextCourse.length)?_c('a-empty',{attrs:{"description":_vm.$t('no_data')}}):_vm._e()],1):_vm._e(),(_vm.isLoading)?_c('Loading'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }