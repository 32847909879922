<template lang="">
    <div class="course__item">
        <div class="course__image">
            <router-link :to="{ path: `/course-detail-page/${data.id}` }">
                <img :src="data.thumbnail" alt="course-image" v-if="linkImgRule(data.thumbnail)" />
                <img src="../../../assets/images/no_image.jpg" alt="course-image" v-else />
            </router-link>
        </div>
        <div class="course__info">
            <router-link :to="{ path: `/course-detail-page/${data.id}` }">
                <h4 class="course__title">{{ data.title }}</h4>
            </router-link>
            <div class="course__time">
                <i class="fa-regular fa-clock"></i>
                <p v-if="data.start_date && data.end_date">
                    <span>{{ formatTimeCourse(data.start_date) }}</span>
                    <span>~</span>
                    <span>{{ formatTimeCourse(data.end_date) }}</span>
                </p>
                <p v-else>{{ $t('anytime') }}</p>
            </div>
            <p class="course__desc">{{ data.description || '' }}</p>
            <div class="course_hashtag">
                <a-tooltip v-if="data.array_hashtag && data.array_hashtag.length > 0">
                    <template slot="title">{{ handleRenderHashtag() }}</template>
                    <p>{{ handleRenderHashtag() }}</p>
                </a-tooltip>
            </div>
            <router-link :to="{ path: `/course-detail-page/${data.id}` }">
                <button>{{ $t('learn_now') }}</button>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mixinRules, mixinFormats } from '../../../mixins';

export default {
    mixins: [mixinRules, mixinFormats],
    props: {
        data: Object,
    },
    methods: {
        handleRenderHashtag() {
            let stringHashtag = '';
            const array = this.data.array_hashtag;
            if (array && array.length > 0) {
                array.forEach((hashtag) => (stringHashtag += `#${hashtag} `));
            }
            return stringHashtag;
        },
    },
};
</script>

<style scoped lang="scss">
.course__item {
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 6px 15px rgb(218 220 237 / 50%);
}

.course__image {
    width: 100%;
    height: 150px;
    overflow: hidden;
    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.4s ease-in-out;
    }
    &:hover img {
        transform: scale(1.1);
    }
}

.course__info {
    padding: 8px 16px 24px;
    & .course__title,
    & .course__desc {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }
    & .course__title {
        min-height: 30px;
        margin-bottom: 8px;
        font-size: 20px;
        font-weight: 700;
        -webkit-line-clamp: 1;
        transition: all 0.4s ease;
        &:hover {
            color: #656ef1;
        }
    }
    & .course__desc {
        min-height: 42px;
        margin-bottom: 8px;
        font-size: 14px;
        color: #747474;
        -webkit-line-clamp: 2;
    }
    & .course__time {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        & i {
            color: #000;
        }
        & p {
            min-height: auto;
            margin-left: 5px;
            margin-bottom: 0;
            font-size: 14px;
        }
        & span {
            margin-left: 5px;
            color: #000;
        }
    }
    & .course_hashtag {
        margin-bottom: 8px;
        min-height: 21px;
        & p {
            margin-bottom: 0;
            max-width: fit-content;
            font-size: 14px;
            color: #747474;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
        }
    }
    & button {
        padding: 8px 12px;
        background-color: #fff;
        color: #000;
        font-size: 14px;
        font-weight: 700;
        border: 1px solid #000;
        border-radius: 4px;
        transition: all 0.4s ease;
        &:hover {
            background-color: #000;
            color: #fff;
        }
    }
}

@media screen and (max-width: 767px) {
    .course__info {
        & h4 {
            font-size: 16px;
        }
        & p,
        & button {
            font-size: 14px;
        }
        & button {
            padding: 6px 10px;
        }
    }

    .course__image {
        height: 200px;
    }
}
</style>
