<template lang="">
    <a-row :gutter="[16, 16]">
        <a-col :xs="24" :sm="12" :lg="6" v-for="(item, index) in list" :key="index">
            <CourseItem :data="item" />
        </a-col>
    </a-row>
</template>

<script>
import CourseItem from './CourseItem.vue';
export default {
    props: {
        list: Array,
    },

    components: {
        CourseItem,
    },
};
</script>

<style scoped lang="scss"></style>
